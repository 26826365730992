/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Link} from "gatsby";
import MapPreview from "../../components/mapPreview";
import BookingSection from "../../components/bookingSection";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Nous vous proposons de partir en week-end à Lille, avec une sélection de sorties immanquables, mêlée à des souvenirs d’enfance. Nous verrons ce qu’il faut visiter, mais aussi dans ", React.createElement(Link, {
    to: "/se-loger-a-lille"
  }, "quels quartiers se loger"), ", manger et sortir à Lille en deux jours."), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Se repérer et se déplacer à Lille"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Prendre des billets d’avion au départ de Bruxelles ou Charleroi, plutôt que de Paris"), "\n", React.createElement(_components.li, null, "Prendre le TGV depuis Paris et regretter l’arrêt en gare d’Amiens Haute-Picardie"), "\n", React.createElement(_components.li, null, "Se réjouir de retrouver la campagne française après avoir passé le tunnel sous la Manche"), "\n", React.createElement(_components.li, null, "S’imaginer dans des montagnes russes dans le métro entre Grand Palais et Porte de Valenciennes"), "\n", React.createElement(_components.li, null, "Prendre un billet TER Trampoline pour la journée, histoire de voir du pays et aller en Belgique"), "\n", React.createElement(_components.li, null, "Avoir appris les noms des principales villes du Nord grâce aux noms des rues de Lille"), "\n", React.createElement(_components.li, null, "Réaliser quelques minutes avant le départ de son train qu’il ne part pas de Lille Flandres, mais de Lille Europe, et inversement"), "\n", React.createElement(_components.li, null, "Avoir connu la Comeli (métro) et la Cotrali (bus TCC), avant Transpole et Ilévia"), "\n", React.createElement(_components.li, null, "Être persuadé que l’on descend à Paris"), "\n", React.createElement(_components.li, null, "Profiter des promotions Ouigo pour inciter ses amis à découvrir la région Hauts-de-France"), "\n"), React.createElement(MapPreview, {
    city: "Lille",
    normalizedCityName: "lille",
    type: "must-see"
  })), "\n", React.createElement(BookingSection, {
    city: "Lille",
    normalizedCityName: "lille"
  }), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "A visiter à Lille"), React.createElement(_components.h3, null, "Patrimoine"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Faire des détours pour passer par la ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Vieille_Bourse",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vieille Bourse")), "\n", React.createElement(_components.li, null, "Penser aux Ducs de Bourgogne en voyant le ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Palais_Rihour",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Palais Rihour")), "\n", React.createElement(_components.li, null, "Vivre en bourgeois flamand au musée de ", React.createElement("a", {
    href: "http://www.lille.fr/Nos-equipements/Musee-de-l-Hospice-Comtesse",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "l’hospice Comtesse")), "\n", React.createElement(_components.li, null, "Se souvenir du destin de ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Roger_Salengro",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Roger Salengro")), "\n", React.createElement(_components.li, null, "Avoir visité la maison d’enfance du Général de Gaulle"), "\n", React.createElement(_components.li, null, "Se sentir chez soi à la vue des restes des fortifications de Vauban à Luxembourg ou sur la forteresse maritime de Suomenlinna à Helsinki"), "\n", React.createElement(_components.li, null, "Se prendre pour Louis XIV en passant à côté de la ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Porte_de_Paris_(Lille)",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "porte de Paris")), "\n", React.createElement(_components.li, null, "Avoir appris à apprécier la façade moderne et sobre de ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame-de-la-Treille_de_Lille",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Notre-Dame de La Treille")), "\n", React.createElement(_components.li, null, "Penser à ", React.createElement("a", {
    href: "http://www.roubaix-lapiscine.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La Piscine"), " chaque fois que l’on vous parle du style Art Déco"), "\n", React.createElement(_components.li, null, "Connaître le rôle de la bataille de ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Fromelles_Australian_Memorial_Park",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Fromelles"), " dans la formation de l’identité nationale australienne"), "\n"), React.createElement(_components.h3, null, "Géographie"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Aller au ", React.createElement("a", {
    href: "http://www.pba-lille.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Palais des Beaux-Arts de Lille"), " uniquement pour contempler la France des Flandres en plans-reliefs"), "\n", React.createElement(_components.li, null, "Avoir grandi avec le poste-frontière entre la France et la Belgique"), "\n", React.createElement(_components.li, null, "Se sentir chez soi à Amsterdam, Bruges ou Copenhague"), "\n", React.createElement(_components.li, null, "Savoir situer sur une carte Roubaix, Tourcoing et les différentes villes de la métropole lilloise"), "\n", React.createElement(_components.li, null, "Considérer normal qu’il y ait une association des chtis à Pékin"), "\n", React.createElement(_components.li, null, "S’étonner de trouver une ", React.createElement("a", {
    href: "http://www.furet.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "librairie Furet du Nord"), " ailleurs que sur la grand place de Lille"), "\n", React.createElement(_components.li, null, "Avoir vu son premier fauve au cirque ou au ", React.createElement("a", {
    href: "http://www.lille.fr/Nos-equipements/Le-Zoo-de-Lille",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "zoo de Lille")), "\n", React.createElement(_components.li, null, "S’imaginer faire l’école buissonnière en voyant les péniches sur la Deûle"), "\n", React.createElement(_components.li, null, "Face à un Indien, se comparer à la situation géographique des habitants des contreforts de l’Himalaya, au Nord de l’Inde"), "\n", React.createElement(_components.li, null, "Se sentir rassuré par la présence du ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Beffroi_de_Lille",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "beffroi de Lille")), "\n"), React.createElement(_components.h3, null, "Histoire industrielle"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Travailler ou étudier dans une ancienne usine textile, voire y participer à des escape games immersifs, comme à ", React.createElement("a", {
    href: "https://koezio.co/koezio-lille/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Koezio")), "\n", React.createElement(_components.li, null, "Savoir que du haut de la Grande roue de la Grande Place, il est possible de voir des inscriptions laissées par des ouvriers sur la ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Chambre_de_commerce_de_Lille",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "chambre de commerce")), "\n", React.createElement(_components.li, null, "Être ému par la brique rouge utilisée pour le collège britannique, la maison des Belges ou le bâtiment norvégien de la ", React.createElement("a", {
    href: "http://www.ciup.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "cité universitaire internationale de Paris")), "\n", React.createElement(_components.li, null, "Savoir qu’il faut dire crassiers pour ménager les susceptibilités locales quand on voit des terrils dans l’Est de la France"), "\n", React.createElement(_components.li, null, "Penser aux cheminées de son enfance devant les fours à brique au milieu de nulle part au Rajasthan"), "\n", React.createElement(_components.li, null, "Être fasciné par les métiers à tisser de la ", React.createElement("a", {
    href: "http://www.lamanufacture-roubaix.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Manufacture des Flandres")), "\n", React.createElement(_components.li, null, "Penser aux générations de mineurs dans la salle des pendus au ", React.createElement("a", {
    href: "http://www.chm-lewarde.com/fr/index.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Musée de la mine"), " à Lewarde"), "\n", React.createElement(_components.li, null, "Être convaincu que l’", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Usine_Motte-Bossut",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "usine Motte-Bossut"), " qui abrite les Archives Nationales du Monde du Travail est héritière des forteresses médiévales"), "\n", React.createElement(_components.li, null, "Se réjouir chaque fois qu’une entreprise implante un centre de recherche à ", React.createElement("a", {
    href: "http://www.euratechnologies.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "EuraTechnologies")), "\n", React.createElement(_components.li, null, "Voir le ", React.createElement("a", {
    href: "http://www.fracnpdc.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "FRAC Grand Large"), " et les raffineries de Dunkerque comme le lointain souvenir d’une sidérurgie \"les pieds dans l’eau\""), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Sortir à Lille"), React.createElement(_components.h3, null, "Culture populaire"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Avoir été terrorisé par les feux d’artifice du Champ-de-Mars"), "\n", React.createElement(_components.li, null, "Connaître les légendes urbaines autour de la Deûle"), "\n", React.createElement(_components.li, null, "Aller à la fête foraine ou sur la côte uniquement pour une gaufre au Nutella"), "\n", React.createElement(_components.li, null, "Être rentré de vacances pour ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Braderie_de_Lille",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "la grande braderie")), "\n", React.createElement(_components.li, null, "Se moquer joyeusement des oeuvres subventionnées dans le cadre des biennales culturelles de Lille"), "\n", React.createElement(_components.li, null, "Guetter le prochain article marronnier sur la fortune des Mulliez dans les hebdos nationaux"), "\n", React.createElement(_components.li, null, "Savoir que ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Gayant",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Monsieur et Madame Gayant"), " veillent sur Douai"), "\n", React.createElement(_components.li, null, "Prétexter une participation à la biennale du rollmops pour éviter une contrainte sociale"), "\n", React.createElement(_components.li, null, "Fêter Saint-Nicolas et chanter le ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/P’tit_Quinquin_(chanson)",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "P’tit Quinquin")), "\n", React.createElement(_components.li, null, "Savoir quels sont les jours fériés d’usage à la mairie de Lille"), "\n"), React.createElement(_components.h3, null, "Sport"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Connaître les intrigues et rebondissements qui ont entouré la construction du ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Stade_Pierre-Mauroy",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Grand stade Pierre-Mauroy")), "\n", React.createElement(_components.li, null, "Avoir couru enfant pour l’inauguration d’", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Euralille",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Euralille")), "\n", React.createElement(_components.li, null, "Se rappeler que Chris Boardman de la Gan a remporté le prologue du tour de France 1994 à Lille, quand tout le public n’en avait que pour Indurain, 2e à 15 secondes."), "\n", React.createElement(_components.li, null, "Se souvenir de la chute de Laurent Jalabert à Armentières, lors du sprint le lendemain"), "\n", React.createElement(_components.li, null, "Avoir vibré pour la qualification du LOSC en ligue des champions en 2001 contre Parme, et pour le doublé de 2011"), "\n", React.createElement(_components.li, null, "Voir les majorettes comme des sportives de haut niveau"), "\n", React.createElement(_components.li, null, "Avoir appris à nager dans des piscines-bulles"), "\n", React.createElement(_components.li, null, "Aller à Comines pour ", React.createElement("a", {
    href: "http://www.ice-mountain.com/fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "faire du ski")), "\n", React.createElement(_components.li, null, "Assister aux ", React.createElement("a", {
    href: "http://www.cerf-volant-berck.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "rencontres internationales de cerfs-volants"), " de Berck-sur-Mer"), "\n", React.createElement(_components.li, null, "Suivre une préparation physique pour les soldes, aux ", React.createElement("a", {
    href: "http://www.usineroubaix.fr/fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "magasins d’usine de Roubaix")), "\n"), React.createElement(_components.h3, null, "Nature"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Savoir qu’une betterave sucrière ressemble à un crâne"), "\n", React.createElement(_components.li, null, "Vanter les mérites de la ", React.createElement("a", {
    href: "http://www.larattedutouquet.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "ratte du Touquet"), " en toutes circonstances"), "\n", React.createElement(_components.li, null, "Regretter les endives au jambon de son enfance et savoir apprécier l’endive en salade, en confit ou en soupe"), "\n", React.createElement(_components.li, null, "Penser avec émotion à la douceur du crachin quand on découvre pour la première fois la mousson"), "\n", React.createElement(_components.li, null, "Rester chez soi quand il drache"), "\n", React.createElement(_components.li, null, "Hésiter entre ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Wimereux",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Wimereux"), ", ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Wissant",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Wissant"), ", le ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Cap_Blanc-Nez",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Blanc-Nez"), " et le ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Cap_Gris-Nez",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Griz-Nez"), " pour aller voir la mer"), "\n", React.createElement(_components.li, null, "Regretter le temps où il n’y avait pas de grilles autour du ", React.createElement("a", {
    href: "http://www.lilletourism.com/parc-et-jardin-lille/parc-jean-baptiste-lebas.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "square Lebas")), "\n", React.createElement(_components.li, null, "Penser à Lille quand on vous parle du ", React.createElement("a", {
    href: "http://www.lilletourism.com/parcs_et_jardins_a_lille-1-0-11-fr.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "bois de Boulogne")), "\n", React.createElement(_components.li, null, "Penser aux marronniers quand on vous parle de \"bogue\""), "\n", React.createElement(_components.li, null, "Penser à un vêtement quand on vous parle de \"maronne\""), "\n"), React.createElement(_components.h3, null, "Culture"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sentir immédiatement ce que veut dire Jean-Christophe Bailly lorsqu’il évoque les faubourgs du Nord dans ", React.createElement("a", {
    href: "https://www.amazon.fr/gp/product/2020974932/ref=as_li_tl?ie=UTF8&camp=1642&creative=19458&creativeASIN=2020974932&linkCode=as2&tag=pietondelair-21&linkId=XT6Z3SCFO6HIQ6EN",
    target: "_blank",
    rel: "noopener noreferrer"
  }, React.createElement("em", null, "Le Dépaysement"))), "\n", React.createElement(_components.li, null, "Aller écouter ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Jacques_Bonnaff%C3%A9",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Jacques Bonnaffé"), " sur scène, et attendre secrètement qu’il déclame des poèmes de ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Jean-Pierre_Verheggen",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Jean-Pierre Verheggen")), "\n", React.createElement(_components.li, null, "Prendre en affection Léon-Paul Fargue quand il écrit dans ", React.createElement("em", null, "Refuges"), " : \"Ces villes que j’ai tant aimées ! Dunkerque, Boulogne et Calais, que Mars, dit-on, frappe d’influences maléfiques, tirent mon regard. Abbeville et sa Collégiale, Cambrai et son beffroi, Arras et ses places, Béthune et la Chambre des Charitables défilent et m’arrêtent. Et Lille, voici Lille où j’allais si souvent avec mon père… Sa citadelle est là pour me rappeler, de la part de Vauban, que les carillons et les géants des Flandres ont vu bien d’autres tempêtes !\""), "\n", React.createElement(_components.li, null, "Rire de bon coeur devant les comédies belges"), "\n", React.createElement(_components.li, null, "Apprécier des films français sans intérêt, uniquement parce qu’ils se passent à Dunkerque ou Calais"), "\n", React.createElement(_components.li, null, "Être persuadé que le ", React.createElement("a", {
    href: "http://www.musee-lam.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "LaM"), " n’a rien à envier aux autres musées d’art moderne"), "\n", React.createElement(_components.li, null, "Être touché par les primitifs flamands, dans tous les musées du monde"), "\n", React.createElement(_components.li, null, "Aller au Nouveau Siècle voir l’orchestre national de Lille, fondé par ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Jean-Claude_Casadesus",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Jean-Claude Casadesus")), "\n", React.createElement(_components.li, null, "Se souvenir de l’ancienne friche industrielle de la ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Gare_de_Lille-Saint-Sauveur",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare Saint Sauveur"), " en visitant Berlin"), "\n", React.createElement(_components.li, null, "Avoir fait un trait sur Dany Boon"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Manger à Lille"), React.createElement(_components.h3, null, "Gastronomie"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Manger de la ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Faluche_(pain)",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "faluche"), " à toute heure, en version sucrée ou salée"), "\n", React.createElement(_components.li, null, "Être persuadé des bienfaits de la cuisine au beurre, sinon pour la santé, du moins sur le moral"), "\n", React.createElement(_components.li, null, "Préférer le ", React.createElement("a", {
    href: "http://androuet.com/Vieux-Lille-1034.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Vieux-Lille"), " sur un plateau de fromages plutôt que dans sa version proprette et enseignes de luxe"), "\n", React.createElement(_components.li, null, "Ne pas minauder lorsqu’on vous parle de tarte au ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Maroilles_(fromage)",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "maroilles")), "\n", React.createElement(_components.li, null, "Faire la différence entre ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Waterzoo%C3%AF",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Waterzooï"), ", Waterloo et Wattrelos"), "\n", React.createElement(_components.li, null, "Garder une place pour la ", React.createElement("a", {
    href: "http://www.hervecuisine.com/recette/la-tarte-au-sucre-chti-du-nord/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "tarte au sucre et vergeoise"), " en dessert"), "\n", React.createElement(_components.li, null, "Savoir écrire et expliquer ce que sont le ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Potjevleesch",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "potjevleesch"), " ou le ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/Welsh_(plat)",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "welsh")), "\n", React.createElement(_components.li, null, "Ecouter chanter la ", React.createElement("a", {
    href: "http://www.odelices.com/recette/carbonade-flamande-r680/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "carbonnade flamande"), " dans sa cocotte"), "\n", React.createElement(_components.li, null, "Ne pas s’étonner des ", React.createElement("a", {
    href: "http://commons.wikimedia.org/wiki/File:Tas_de_moules.jpg",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "monticules de moules"), " devant les restaurants lillois début septembre"), "\n", React.createElement(_components.li, null, "Goûter les charmes de la ", React.createElement("a", {
    href: "http://androuet.com/Boulette-d-avesnes-137.html",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "boulette d’Avesnes"), " et des produits labellisés Saveurs en’Or"), "\n"), React.createElement(_components.h3, null, "Bonnes adresses"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Faire un sourire entendu quand on vous parle des ", React.createElement("a", {
    href: "http://www.auxmerveilleux.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Merveilleux de Fred")), "\n", React.createElement(_components.li, null, "Recommander les gaufres à la vergeoise ", React.createElement("a", {
    href: "http://www.meert.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Méert"), ", en acheter au supermarché faute de mieux"), "\n", React.createElement(_components.li, null, "Faire un arrêt chez ", React.createElement("a", {
    href: "http://www.hema.fr/magasin/manger-et-cuisiner/grignoter",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Hema"), " chaque fois que l’occasion se présente à travers l’Europe pour trouver des gaufres au caramel (stroopwafel)"), "\n", React.createElement(_components.li, null, "Réserver une table chez Marc Meurin et s’inventer une raison d’aller au ", React.createElement("a", {
    href: "http://www.louvrelens.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Louvre-Lens")), "\n", React.createElement(_components.li, null, "Garder son dimanche matin pour le poulet rôti de ", React.createElement("a", {
    href: "https://fr.wikipedia.org/wiki/March%C3%A9_de_Wazemmes",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Wazemmes")), "\n", React.createElement(_components.li, null, "S’étonner que le tiramisu ne soit pas à base de spéculoos et de chicorée dans tous les restaurants"), "\n", React.createElement(_components.li, null, "Aller à Lille uniquement pour la boulangerie d’", React.createElement("a", {
    href: "http://www.alexcroquet.fr/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Alex Croquet")), "\n", React.createElement(_components.li, null, "Hanter le quartier européen de Bruxelles pour prendre un cornet chez ", React.createElement("a", {
    href: "http://www.maisonantoine.be/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Antoine")), "\n", React.createElement(_components.li, null, "Regretter les mauvaises huiles de cuisson de la friterie du coin, mais y retourner pour tester des sauces inédites"), "\n", React.createElement(_components.li, null, "Se retrouver un jour de pluie à l’estaminet"), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Poursuivre son voyage après Lille"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Les principales villes touristiques en France sont ", React.createElement(Link, {
    to: "/week-end-a-paris-les-incontournables"
  }, "Paris"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/bordeaux.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bordeaux"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/lyon.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Lyon"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/marseille.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Marseille"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/montpellier.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Montpellier"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/nantes.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Nantes"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/nice.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Nice"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/rennes.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Rennes"), ", ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/strasbourg.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Strasbourg"), " et ", React.createElement("a", {
    href: "https://www.booking.com/city/fr/toulouse.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Toulouse"), "."), "\n", React.createElement(_components.li, null, "Les villes européennes les plus proches de Lille sont ", React.createElement(Link, {
    to: "/week-end-a-bruges-les-incontournables"
  }, "Bruges"), " et ", React.createElement(Link, {
    to: "/week-end-a-bruxelles-les-incontournables"
  }, "Bruxelles"), " en Belgique, ", React.createElement("a", {
    href: "https://www.booking.com/city/nl/rotterdam.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Rotterdam"), " et ", React.createElement(Link, {
    to: "/week-end-a-amsterdam-les-incontournables"
  }, "Amsterdam aux Pays-Bas"), ", ", React.createElement(Link, {
    to: "/week-end-a-londres-les-incontournables"
  }, "Londres au Royaume-Uni"), ", ", React.createElement(Link, {
    to: "/week-end-a-luxembourg-les-incontournables"
  }, "Luxembourg au Luxembourg"), " ainsi que ", React.createElement("a", {
    href: "https://www.booking.com/city/de/cologne.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Cologne"), " et ", React.createElement("a", {
    href: "https://www.booking.com/city/de/frankfurt-am-main.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Francfort-sur-le-Main en Allemagne"), "."), "\n")), "\n", React.createElement("section", null, React.createElement(_components.h2, null, "Pour aller plus loin"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Petit+Fut%C3%A9+Autour+de+Lille&linkCode=ll2&tag=pietondelair-21&linkId=53f55977d0e0f2763747c38a046cf04b",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Petit Futé Autour de Lille")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Petit+Fut%C3%A9+Lille+m%C3%A9tropole&rh=n:301061,k:Petit+Fut%C3%A9+Lille+m%C3%A9tropole&linkCode=ll2&tag=pietondelair-21&linkId=e3fb61c7f92d86c7a7a31066c6592d2f",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Petit Futé Lille métropole")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Lille+en+quelques+jours&rh=n:301061,k:Lille+en+quelques+jours&linkCode=ll2&tag=pietondelair-21&linkId=8524353c171a297decf16949e0a4c55e",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Lonely Planet Lille en quelques jours")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Guide+Vert+Weekend+Lille+Michelin&rh=n:301061,k:Guide+Vert+Weekend+Lille+Michelin&linkCode=ll2&tag=pietondelair-21&linkId=3e754730790def608b2b232d9905f874",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Guide Vert Week-end à Lille avec plan détachable")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Guide+du+Routard+Nord-Pas+de+Calais&rh=n:301061,k:Guide+du+Routard+Nord-Pas+de+Calais&linkCode=ll2&tag=pietondelair-21&linkId=522cfab35450f28c49ad58a68974208f",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Guide du Routard Nord-Pas de Calais")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: "https://www.amazon.fr/s/ref=as_li_ss_tl?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&url=search-alias=stripbooks&field-keywords=Petit+Fut%C3%A9+Hauts-de-France+-+Nord-Pas-de-Calais&rh=n:301061,k:Petit+Fut%C3%A9+Hauts-de-France+-+Nord-Pas-de-Calais&linkCode=ll2&tag=pietondelair-21&linkId=ee99920a4a1ceaabd12208cf65f4da5a",
    target: "_blank",
    rel: "nofollow noopener noreferrer"
  }, React.createElement("span", null, "Petit Futé Hauts-de-France - Nord-Pas-de-Calais")), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
